<template>
  <div class="login">
    <!-- 导航栏 -->
    <nav-header :href="this.href"></nav-header>
    <div class="container">
      <!-- 标题 -->
      <div class="login-title">在线充值</div>
      <!-- 容器 -->
      <div class="login-con">
        <!-- input 框  循环项-->
        <div class="login-input">
          <span>用户名：</span>
          <input type="text" placeholder="请输入用户名" />
        </div>
        <div class="login-input">
          <span>密码：</span>
          <input type="passworld" placeholder="请输入密码" />
        </div>
        <div class="login-input">
          <span>账号：</span>
          <input type="text" placeholder="请输入账号" />
        </div>
        <div class="login-input">
          <span>确认账号：</span>
          <input type="text" placeholder="请确认账号" />
        </div>

        <!-- 充值金额 -->
        <div class="login-input">
          <span>充值金额：</span>
          <span class="login-btn active">10元</span>
          <span class="login-btn">30元</span>
          <span class="login-btn">50元</span>
          <span class="login-btn">其他</span>
        </div>

        <div class="login-list">
          <img class="active" src="/imgs/1.png" alt="" />
          <img src="/imgs/2.png" alt="" />
          <img src="/imgs/3.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavHeader from "./../components/NavHeader";
export default {
  data() {
    return {
      href: "index.html",
      ac1: [],
      ac2: [],
      ac3: [],
      ac4: [],
      ac5: [],
      showModal: false,
      state: JSON.parse(window.localStorage.getItem("state")),
      pay: JSON.parse(window.localStorage.getItem("pay")),
    };
  },
  mounted() {
    document.title = "北京聚元兴科技有限公司";
  },
  components: {
    NavHeader,
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.login {
  width: 100%;
  position: relative;
  background-color: #f5f5f5;
  .container {
    height: 500px;
    background-color: #fff;
    margin: 40px auto;
    padding: 40px;
    box-sizing: border-box;
    // text-align: center;
    // 标题
    .login-title {
      font-size: 21px;
      color: #ff6600;
      text-align: center;
      margin: 10px auto;
    }
    // 容器
    .login-con {
      width: 583px;
      height: 80%;
      // background-color: pink;
      margin: 20px auto;
      // text-align: center;
      // 循环项
      .login-input {
        width: 100%;
        height: 30px;
        margin: 10px 0;
        background-color: #fff;
        line-height: 30px;
        span {
          font-size: 16px;
          font-weight: bold;
          margin-right: 15px;
          display: inline-block;
          width: 100px;
        }
        input {
          width: 300px;
          height: 100%;
          list-style: none;
          border: 1px solid #ff6600;
          font-size: 12px;
          color: #888;
          padding-left: 10px;
          box-sizing: border-box;
        }
        // 按钮
        .login-btn {
          width: 60px;
          height: 30px;
          // background-color: #39aeff;
          color: #ff6600;
          border: 2px solid #ff6600;
          font-size: 12px;
          text-align: center;
          // border-radius: 30px;
          line-height: 30px;
          cursor: pointer;
          transition: all 0.3s;
          &.active {
            background-color: #ff6600;
            color: #fff;
          }
          &:hover {
            background-color: #ff6600;
            color: #fff;
          }
        }
      }

      .login-list {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 30px;
        img {
          width: 30%;
          height: 100%;
          border: 3px solid #555;
          &.active {
            border: 3px solid #ff6600;
          }
          &:hover {
            border: 3px solid #ff6600;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>