<template>
  <div>
    <!-- <nav-header></nav-header> -->
    <router-view></router-view>
    <nav-footer
      :email="this.email"
      :phone="this.phone"
      :name="this.name"
      :addr="this.addr"
      :icp="this.icp"
    ></nav-footer>
  </div>
</template>

<script>
import NavFooter from "./../components/NavFooter";
export default {
  name: "home",
  components: {
    NavFooter,
  },
  methods() {
    document.title = "宽腾合一";
  },
  data() {
    return {
      email: "邮箱：barqw1982@163.com",
      phone: "电话：18601312632",
      icp: "ICP备案/许可证号：京ICP备19019099号-1",
      name: "北京宽腾合一科技有限公司",
      addr: "联系地址：北京市海淀区信息路22号B座2层357室",
    };
  },
};
</script>
<style lang="" scoped></style>
