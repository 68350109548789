<template>
  <div class="footer">
    <div class="container">
      <div class="f-list">
        <h3>北京宽腾合一科技有限公司 </h3>
        <ul>
          <li @click="goHome()">首页</li>
          <li @click="goAc1()">有偿资讯</li>
          <!-- <li  @click="goAc2()">行业资讯</li> -->
          <!-- <li @click="goAc3()">解决方案</li> -->
        </ul>
      </div>

      <div class="f-list">
        <h3>联系方式</h3>
        <ul>
          <li>{{ this.name }}</li>
          <li>{{ this.email }}</li>
          <!-- <li>{{ this.phone }}</li> -->
          <li>
            <a href="https://beian.miit.gov.cn/#/Integrated/index">{{
              this.icp
            }}</a>
          </li>
          <li>{{ this.addr }}</li>
        </ul>
      </div>

      <div class="f-list">
        
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "nav-footer",

  props: {
    email: String,
    phone: String,
    icp: String,
    name: String,
    addr: String,
  },
  methods: {
    // 跳转最新文章
    goAc1() {
      this.$router.push("/ac1");
    },
    // 跳转行业资讯
    goAc2() {
      this.$router.push("/ac2");
    },
    // 跳转独加新闻
    goAc3() {
      this.$router.push("/ac3");
    },
    // 跳转关于我们
    goInfo() {
      this.$router.push("/info");
    },
    goHome() {
      this.$router.push("/index");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";

.footer {
  width: 100%;
  height: 200px;
  background: #23262b;
  padding: 20px;
  box-sizing: border-box;
  color: #fff;
  .container {
    @include flex();
    .f-list {
      width: 200px;
      height: 120px;
      // background-color: pink;
      ul {
        li {
          font-size: 14px;
          margin: 10px auto;
          width: 420px;
          cursor: pointer;
          a {
            color: #fff;
          }
        }
      }
      p {
        display: flex;
        flex-wrap: wrap;
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
}
</style>
