<template>
  <div class="info">
    <!-- 导航栏 -->
    <nav-header :href="this.href"></nav-header>

    <!-- 定宽 -->
    <div class="container">
      <!-- 左侧 导航 -->
      <div class="info-1" >
        <ul>
          <li class="active">关于我们</li>
        </ul>
      </div>
      <!-- 右侧 内容 -->
      <div class="info-2">
        <h3>关于我们</h3>
        <!-- 下环线 -->
        <div class="bink"></div>

        <!-- banenr -->
        <div class="i2-banner"></div>
        <div class="i2-con">
          <h3>公司名称：北京恺悦电子商务有限公司</h3>
          <h3>电话：18600882011</h3>
          <h3>域名：kaiyuedianzi.com</h3>
          <h3>邮箱：604819314@qq.com </h3>
          <h3><a href="https://beian.miit.gov.cn/#/Integrated/index">备案号：京ICP备2023008344号-3</a></h3>
          <p>
            北京恺悦电子商务有限公司成立于2022年08月02日，注册地位于北京市通州区永乐店镇柴厂屯村东（联航大厦）1-4397号，经营范围包括一般项目：互联网销售（除销售需要许可的商品）；销售代理；居民日常生活服务；供应链管理服务；食品销售（仅销售预包装食品）；品牌管理；国内贸易代理；礼仪服务；服装服饰零售；食用农产品零售；新鲜水果零售；新鲜蔬菜零售；五金产品零售；日用百货销售；劳动保护用品销售；鞋帽零售；美发饰品销售；珠宝首饰零售；汽车装饰用品销售；计算机软硬件及辅助设备零售；电子产品销售；体育用品及器材零售；文具用品批发；婚庆礼仪服务；工艺美术品及收藏品零售（象牙及其制品除外）；化妆品零售；专业保洁、清洗、消毒服务；家政服务；日用化学产品销售；厨具卫具及日用杂品零售；茶具销售；农副产品销售；技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广；摄影扩印服务；打字复印。（除依法须经批准的项目外，凭营业执照依法自主开展经营活动）许可项目：第二类增值电信业务；第一类增值电信业务；互联网信息服务；酒类经营。（依法须经批准的项目，经相关部门批准后方可开展经营活动，具体经营项目以相关部门批准文件或许可证件为准）（不得从事国家和本市产业政策禁止和限制类项目的经营活动。）
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavHeader from "./../components/NavHeader";
export default {
  name: "info",
  components: {
    NavHeader,
  },
  data() {
    return {
      href: "index.html",
    };
  },
  mounted() {
    document.title = "北京恺悦电子商务有限公司 ";
  },
  methods: {
    // 跳转关于我们
    goInfo() {
      this.$router.push("/info");
    },
    // 跳转注册协议
    goXy() {
      this.$router.push("/xy");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";

.info {
  width: 100%;
  background-color: #f0f0f0;

  // banner
  .i-banner {
    width: 100%;
    height: 250px;
    background-color: pink;
    margin-bottom: 120px;
  }

  // 定宽布局
  .container {
    // @include flex();
    display: flex;
    justify-content: space-between;
    // height: 800px;
    margin-bottom: 30px;

    // 左侧
    .info-1 {
      width: 30%;
      height: 100%;
      margin-right: 20px;

      // background-color: red;
      ul {
        width: 190px;
        background-color: #fff;
        border-bottom: 2px solid #dfe1e2;
        border-radius: 3px;
        padding: 13px 15px;
        margin: 0 auto;

        li {
          width: 90%;
          height: 40px;
          line-height: 40px;
          font-size: 14px;
          font-weight: bold;

          box-sizing: border-box;
          margin: 10px auto;
          cursor: pointer;
          padding-left: 20px;

          &.active {
            background-color: #3890e1;
            color: #fff;
          }
        }
      }
    }

    // 右侧
    .info-2 {
      width: 70%;
      height: 100%;
      background: #fff;
      padding-top: 20px;
      box-sizing: border-box;
      text-align: center;

      h3 {
        font-size: 24px;
      }

      // 下划线
      .bink {
        width: 30px;
        height: 3px;
        background-color: #000;
        margin: 10px auto;
      }

      // banner
      .i2-banner {
        width: 100%;
        height: 300px;
        background: url("/imgs/b1.jpg") no-repeat center;
        background-size: cover;
        margin: 30px auto;
      }

      // 文章
      .i2-con {
        p {
          font-size: 13px;
          line-height: 3.2em;
          color: #4e4b4b;
          margin: 15px auto;
          padding: 10px;
          box-sizing: border-box;
          text-align: left;
        }

        h3 {
          font-size: 18px;
          margin: 15px auto;
        }
      }
    }
  }
}
</style>
