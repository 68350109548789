<template>
  <div class="ac1">
    <!-- 导航栏 -->
    <nav-header :href="this.href"></nav-header>

    <div class="container">
       <!-- 资讯区块3 -->
      <div class="s3">
        <!-- 左侧 -->
        <div class="s3-1">
          <!-- 标题 -->
          <div class="s3-title">最新文章</div>
          <!-- 列表内容 -->
          <div class="s3-con">
            <ul>
              <!-- 循环项 -->
              <li class="s3-item">
                <!-- 图片 -->
                <div class="s3-img">
                  <img src="" alt="" />
                </div>
                <!-- 信息 -->
                <div class="s3-info">
                  <!-- 标题 -->
                  <div class="s3-t">《王牌3》发布会沈腾幽默上线 王牌队长艺能</div>
                  <!-- 信息层 -->
                  <div class="s3-user">
                    <!-- 时间 -->
                    <span class="s3-time">
                      <i></i>
                      2020-12-29
                    </span>
                    <!-- 作者 -->
                    <span class="s3-author">
                      <i></i>
                      梁璐
                    </span>
                  </div>

                  <!-- 介绍 -->
                  <div class="s3-brier">
                    <p>XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX</p>
                  </div>
                </div>
              </li>

              <!-- 循环项 -->
              <li class="s3-item">
                <!-- 图片 -->
                <div class="s3-img">
                  <img src="" alt="" />
                </div>
                <!-- 信息 -->
                <div class="s3-info">
                  <!-- 标题 -->
                  <div class="s3-t">《王牌3》发布会沈腾幽默上线 王牌队长艺能</div>
                  <!-- 信息层 -->
                  <div class="s3-user">
                    <!-- 时间 -->
                    <span class="s3-time">
                      <i></i>
                      2020-12-29
                    </span>
                    <!-- 作者 -->
                    <span class="s3-author">
                      <i></i>
                      梁璐
                    </span>
                  </div>

                  <!-- 介绍 -->
                  <div class="s3-brier">
                    <p>XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX</p>
                  </div>
                </div>
              </li>

              <!-- 循环项 -->
              <li class="s3-item">
                <!-- 图片 -->
                <div class="s3-img">
                  <img src="" alt="" />
                </div>
                <!-- 信息 -->
                <div class="s3-info">
                  <!-- 标题 -->
                  <div class="s3-t">《王牌3》发布会沈腾幽默上线 王牌队长艺能</div>
                  <!-- 信息层 -->
                  <div class="s3-user">
                    <!-- 时间 -->
                    <span class="s3-time">
                      <i></i>
                      2020-12-29
                    </span>
                    <!-- 作者 -->
                    <span class="s3-author">
                      <i></i>
                      梁璐
                    </span>
                  </div>

                  <!-- 介绍 -->
                  <div class="s3-brier">
                    <p>XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX</p>
                  </div>
                </div>
              </li>

              <!-- 循环项 -->
              <li class="s3-item">
                <!-- 图片 -->
                <div class="s3-img">
                  <img src="" alt="" />
                </div>
                <!-- 信息 -->
                <div class="s3-info">
                  <!-- 标题 -->
                  <div class="s3-t">《王牌3》发布会沈腾幽默上线 王牌队长艺能</div>
                  <!-- 信息层 -->
                  <div class="s3-user">
                    <!-- 时间 -->
                    <span class="s3-time">
                      <i></i>
                      2020-12-29
                    </span>
                    <!-- 作者 -->
                    <span class="s3-author">
                      <i></i>
                      梁璐
                    </span>
                  </div>

                  <!-- 介绍 -->
                  <div class="s3-brier">
                    <p>XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX</p>
                  </div>
                </div>
              </li>

              <!-- 循环项 -->
              <li class="s3-item">
                <!-- 图片 -->
                <div class="s3-img">
                  <img src="" alt="" />
                </div>
                <!-- 信息 -->
                <div class="s3-info">
                  <!-- 标题 -->
                  <div class="s3-t">《王牌3》发布会沈腾幽默上线 王牌队长艺能</div>
                  <!-- 信息层 -->
                  <div class="s3-user">
                    <!-- 时间 -->
                    <span class="s3-time">
                      <i></i>
                      2020-12-29
                    </span>
                    <!-- 作者 -->
                    <span class="s3-author">
                      <i></i>
                      梁璐
                    </span>
                  </div>

                  <!-- 介绍 -->
                  <div class="s3-brier">
                    <p>XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX</p>
                  </div>
                </div>
              </li>

              <!-- 循环项 -->
              <li class="s3-item">
                <!-- 图片 -->
                <div class="s3-img">
                  <img src="" alt="" />
                </div>
                <!-- 信息 -->
                <div class="s3-info">
                  <!-- 标题 -->
                  <div class="s3-t">《王牌3》发布会沈腾幽默上线 王牌队长艺能</div>
                  <!-- 信息层 -->
                  <div class="s3-user">
                    <!-- 时间 -->
                    <span class="s3-time">
                      <i></i>
                      2020-12-29
                    </span>
                    <!-- 作者 -->
                    <span class="s3-author">
                      <i></i>
                      梁璐
                    </span>
                  </div>

                  <!-- 介绍 -->
                  <div class="s3-brier">
                    <p>XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX</p>
                  </div>
                </div>
              </li>

              <!-- 循环项 -->
              <li class="s3-item">
                <!-- 图片 -->
                <div class="s3-img">
                  <img src="" alt="" />
                </div>
                <!-- 信息 -->
                <div class="s3-info">
                  <!-- 标题 -->
                  <div class="s3-t">《王牌3》发布会沈腾幽默上线 王牌队长艺能</div>
                  <!-- 信息层 -->
                  <div class="s3-user">
                    <!-- 时间 -->
                    <span class="s3-time">
                      <i></i>
                      2020-12-29
                    </span>
                    <!-- 作者 -->
                    <span class="s3-author">
                      <i></i>
                      梁璐
                    </span>
                  </div>

                  <!-- 介绍 -->
                  <div class="s3-brier">
                    <p>XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <!-- 右侧 -->
        <div class="s3-2">
          <!-- 右1 -->
          <div class="ss3-1">
            <!-- 标题 -->
            <div class="ss3-title">最新文章</div>
            <!-- 下层容器 -->
            <div class="ss3-con">
              <!-- 循环项 -->
              <div class="ss3-item">
                <!-- 左侧id -->
                <div class="ss3-id">No <i>1</i></div>

                <!-- 信息 -->
                <div class="ss3-info">
                  <!-- 标题 -->
                  <div class="ss3-t">XXXXXXXXXXXXXXXXX</div>
                  <div class="ss3-brief">
                    XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
                  </div>
                </div>
              </div>
              <!-- 循环项 -->
              <div class="ss3-item">
                <!-- 左侧id -->
                <div class="ss3-id">No <i>1</i></div>

                <!-- 信息 -->
                <div class="ss3-info">
                  <!-- 标题 -->
                  <div class="ss3-t">XXXXXXXXXXXXXXXXX</div>
                  <div class="ss3-brief">
                    XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
                  </div>
                </div>
              </div>
              <!-- 循环项 -->
              <div class="ss3-item">
                <!-- 左侧id -->
                <div class="ss3-id">No <i>1</i></div>

                <!-- 信息 -->
                <div class="ss3-info">
                  <!-- 标题 -->
                  <div class="ss3-t">XXXXXXXXXXXXXXXXX</div>
                  <div class="ss3-brief">
                    XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- banner -->
          <div class="s3-banner"></div>

          <!-- 右2 -->
          <div class="ss3-2">
            <!-- 标题 -->
            <div class="ss3-title">娱乐八卦</div>
            <!-- 主体 -->
            <div class="ss3-con">
              <!-- 循环项 -->
              <div class="ss3-lsit">
                <img src="" alt="" />
                XXXX
              </div>
              <!-- 循环项 -->
              <div class="ss3-lsit">
                <img src="" alt="" />
                XXXX
              </div>
              <!-- 循环项 -->
              <div class="ss3-lsit">
                <img src="" alt="" />
                XXXX
              </div>
              <!-- 循环项 -->
              <div class="ss3-lsit">
                <img src="" alt="" />
                XXXX
              </div>
            </div>
          </div>

          <!-- banenr -->
          <div class="s3-banenr2"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavHeader from "./../components/NavHeader";
export default {
  name: "ac1",
  components: {
    NavHeader,
  },
  data() {
    return {
      href:'index.html'
    }
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.ac1 {
  .container {
   // 资讯区块3
    .s3 {
      width: 100%;
      // height: 2000px;
      margin: 80px auto;
      // background-color: pink;
      display: flex;
      justify-content: space-between;
      // 左侧
      .s3-1 {
        width: 70%;
        height: 100%;
        // background-color: #ff6600;
        margin-right: 40px;
        // 标题
        .s3-title {
          width: 100%;
          height: 60px;
          background-color: #fff;
          font-size: 18px;
          font-weight: bold;
          color: #121212;
          line-height: 60px;
          padding-left: 20px;
          box-sizing: border-box;
          border-left: 6px solid #ff6600;
        }

        // 下层容器
        .s3-con {
          width: 100%;
          margin: 10px auto;
          ul {
            width: 100%;
            // 循环项
            .s3-item {
              width: 100%;
              height: 200px;
              background-color: burlywood;
              margin: 15px auto;
              @include flex();
              // 左侧
              .s3-img {
                width: 200px;
                height: 200px;
                background-color: red;
                img {
                  width: 100%;
                  height: 100%;
                }
              }
              // 右侧
              .s3-info {
                width: 650px;
                height: 100%;
                background-color: #c60023;
                padding: 20px;
                box-sizing: border-box;
                // 标题
                .s3-t {
                  font-size: 16px;
                  font-weight: bold;
                }
                // 时间 / 作者层
                .s3-user {
                  span {
                    font-size: 14px;
                    color: #121212;
                    margin: 0 15px;
                  }
                }
                // 简介
                .s3-brier {
                  font-size: 13px;
                  margin-top: 20px;
                  width: 400px;
                  // background-color: #fff;
                  white-space: nowrap; //不换行
                  overflow: hidden; //超出隐藏
                  text-overflow: ellipsis; //变成...
                }
              }
            }
          }
        }
      }
      // 右侧
      .s3-2 {
        width: 30%;
        height: 100%;
        // background-color: aqua;
        box-sizing: border-box;
        // 资讯区块1
        .ss3-1 {
          // background-color: pink;
          width: 100%;
          height: 400px;
          margin-bottom: 40px;
          box-sizing: border-box;
          // 标题
          .ss3-title {
            width: 100%;
            height: 50px;
            line-height: 50px;
            font-size: 16px;
            font-weight: bold;
            border-left: 6px solid #121212;
            padding-left: 20px;
            box-sizing: border-box;
            border-bottom: 2px solid #121212;
            margin-bottom: 30px;
          }

          // 主体内容
          .ss3-con {
            width: 100%;
            // 循环项
            .ss3-item {
              width: 100%;
              height: 100px;
              background-color: #fff;
              display: flex;
              align-items: center;
              margin: 5px auto;
              // 左侧id
              .ss3-id {
                width: 100px;
                height: 100px;
                font-size: 18px;
                text-align: center;
                line-height: 100px;
                background-color: #ff6600;
              }
              // 右侧信息
              .ss3-info {
                width: 70%;

                .ss3-brief {
                  width: 200px;
                  white-space: nowrap; //不换行
                  overflow: hidden; //超出隐藏
                  text-overflow: ellipsis; //变成...
                }
              }
            }
          }
        }

        // banner1
        .s3-banner {
          width: 100%;
          height: 300px;
          background-color: red;
          margin: 40px auto;
        }

        // 资讯区块2
        .ss3-2 {
          width: 100%;
          height: 400px;
          margin: 30px auto;
          background-color: #fff;
          // 标题
          .ss3-title {
            width: 100%;
            height: 50px;
            padding-left: 20px;
            border-left: 6px solid #ff6600;
            line-height: 50px;
            font-size: 16px;
            font-weight: bold;
            box-sizing: border-box;
            // background-color:coral;
            border-bottom: 1px sollid #121212;
          }
          // 容器
          .ss3-con {
            width: 100%;
            height: 350px;
            background-color: yellow;
            // 循环项
            .ss3-lsit {
              width: 140px;
              height: 140px;
              background-color: #fff;
              float: left;
              margin: 14px;
              text-align: center;
              // 图片
              img {
                width: 100%;
                height: 130px;
              }
              span {
                font-size: 14px;
                color: #121212;
              }
            }
          }
        }

        // banner2
        .s3-banenr2 {
          width: 100%;
          height: 300px;
          background-color: red;
          margin: 40px auto;
        }
      }
    }
  }
}
</style>
