<template>
  <div class="index">
    <!-- 导航栏 -->
    <nav-header :href="this.href"></nav-header>

    <div class="container">
      <!-- 资讯区块1 -->
      <div class="s1">
        <!-- 左侧导航 -->
        <div class="swiper"></div>
        <!-- 右侧图片列表 -->
        <div class="s1-con">
          <!-- 循环项 *4 -->
          <div
            class="s1-item"
            v-for="(item, index) in ac1"
            :key="index"
            @click="goDays(item.methods, item.id)"
          >
            <img :src="item.img" alt="" />
            <p>{{ item.title }} - 收费:2元 </p>
          </div>
        </div>
      </div>

      <!-- 资讯区块2 -->
      <div class="s2">
        <!-- 标题 -->
        <div class="s2-title">
          <span>热门资讯</span>
        </div>

        <!-- 下层 -->
        <div class="s2-con">
          <!-- 左侧 -->
          <div class="s2-1">
            <!-- 循环项 -->
            <div
              class="s2-item"
              v-for="(item, index) in ac2"
              :key="index"
              @click="goDays(item.methods, item.id)"
            >
              <!-- 左侧图片 -->
              <div class="s2-img">
                <img :src="item.img" alt="" />
              </div>
              <!-- 右侧信息 -->
              <div class="s2-info">
                <!-- 标题 -->
                <div class="s2-t">{{ item.title }}</div>
                <!-- 简介 -->
                <div class="s2-brief">{{ item.article1 }}</div>
                <p style="color:#c60023;font-weight:bold;">收费阅读：2元</p>
              </div>
            </div>
          </div>

          <!-- 右侧 -->
          <div class="s2-2">
            <img src="/imgs/11.jpg" alt="" />
            <span>买地买楼，互联网大厂的标配？</span>
          </div>
        </div>
      </div>



      <!-- 资讯区块3 -->
      <div class="s3">
        <!-- 左侧 -->
        <div class="s3-1">
          <!-- 标题 -->
          <div class="s3-title">行业资讯</div>
          <!-- 列表内容 -->
          <div class="s3-con">
            <ul>
              <!-- 循环项 -->
              <li
                class="s3-item"
                v-for="(item, index) in ac6"
                :key="index"
                @click="gopay(item.methods, item.id)"
              >
                <!-- 图片 -->
                <div class="s3-img">
                  <img :src="item.img" alt="" />
                </div>
                <!-- 信息 -->
                <div class="s3-info">
                  <!-- 标题 -->
                  <div class="s3-t">
                    {{ item.title }}
                  </div>
                  <!-- 信息层 -->
                  <div class="s3-user">
                    <!-- 时间 -->
                    <span class="s3-time">
                      <i></i>
                      {{ item.time }}
                    </span>
                    <!-- 作者 -->
                    <span class="s3-author">
                      <i></i>
                      {{ item.author }}
                    </span>
                    <!-- 收费 -->
                    <span class="s3-pay" style="color:#c60023;font-weight:bold;">
                     想查看完整内容需收费2元/条 
                    </span>
                  </div>

                  <!-- 介绍 -->
                  <div class="s3-brier">
                    <p>{{ item.article1 }}</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <!-- 右侧 -->
        <div class="s3-2">
          <!-- 右1 -->
          <div class="ss3-1">
            <!-- 标题 -->
            <div class="ss3-title">最新文章</div>
            <!-- 下层容器 -->
            <div class="ss3-con">
              <!-- 循环项 -->
              <div
                class="ss3-item"
                v-for="(item, index) in ac3"
                :key="index"
                @click="goDays(item.methods, item.id)"
              >
                <!-- 左侧id -->
                <div class="ss3-id">
                  No <span>{{ item.id }}</span>
                </div>

                <!-- 信息 -->
                <div class="ss3-info">
                  <!-- 标题 -->
                  <div class="ss3-t">{{ item.title }}</div>
                  <div class="ss3-brief">
                    {{ item.article1 }}
                  </div>
                   <p style="color:#c60023;font-weight:bold;">收费阅读：2元</p>
                </div>
              </div>
            </div>
          </div>

          <!-- banner -->
          <div class="s3-banner"></div>

          <!-- 右2 -->
          <div class="ss3-2">
            <!-- 标题 -->
            <div class="ss3-title">最新文章</div>
            <!-- 主体 -->
            <div class="ss3-con">
              <!-- 循环项 -->
              <div
                class="ss3-lsit"
                v-for="(item, index) in ac5"
                :key="index"
                @click="goDays(item.methods, item.id)"
              >
                <img :src="item.img" alt="" />
                {{ item.title }}

                <p style="color:#c60023;font-weight:bold;">收费阅读：2元</p>
              </div>
            </div>
          </div>

          <!-- banenr -->
          <div class="s3-banenr2"></div>
        </div>
      </div>
    </div>
    <modal
      title="请选择支付方式"
      btnType="1"
      modalType="middle"
      :showModal="showModal"
      @submit="showModal = false"
      @cancel="showModal = false"
    >
      <template v-slot:body>
        <div class="m-l">
          <img src="/imgs/pay2.png" />
          <p>微信支付：2元/条</p>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import NavHeader from "./../components/NavHeader";
import Modal from "./../components/Modal";
export default {
  name: "index",
  components: {
    NavHeader,
    Modal,
  },
  data() {
    return {
      href: "index.html",
      state: JSON.parse(window.localStorage.getItem("state")),
      showModal: false,
      ac1: [],
      ac2: [],
      ac3: [],
      ac4: [],
      ac5: [],
    };
  },
  mounted() {
    this.init1();
    document.title = "宽腾合一";
  },
  methods: {
    init1() {
      this.axios.get("/mock/ac1.json").then((res) => {
        const data = res.data;
        // 咨询区块1
        this.ac1 = data.slice(0, 4);
        this.ac2 = data.slice(4, 8);
        this.ac3 = data.slice(4, 7);
        this.ac4 = data.slice(10, 25);
        this.ac5 = data.slice(6, 10);
        this.ac6 = data.slice(0,11);
      });
    },

    // 跳转功能
    goDays(methodsWords, id) {
      this[methodsWords](id);
    },
    // 跳转详情页
    goDetails(id) {
      this.$router.push({
        path: "/sound/:" + id,
        query: { type: id },
      });
    },
    // 收费逻辑
    gopay() {
       alert("资讯每条2元，请购买后阅读~");
      this.showModal = true;
      // if (this.state == 0) {
      //   // this.$router.push("/pay");
      //   this.showModal = true;
      //   // console.log(this.showModal);
      //   // this.$router.push({ path: "/login", query: { k: 1 } });
      // }
      // // 未登录
      // if (this.state !== 0) {
      //   alert("资讯每条2元，点击确定注册购买");
      //   this.$router.push({ path: "/login", query: { k: 1 } });
      //   return;
      // }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.index {
  background-color: #f0f0f0;
  .container {
    // 资讯区块1
    background-color: #f0f0f0;
    .s1 {
      width: 100%;
      // height: 400px;
      // background-color: pink;
      margin: 40px auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      // 左侧
      .swiper {
        width: 70%;
        height: 390px;
        // background: red;
        background: url("/imgs/b1.jpg") no-repeat center;
        background-size: cover;
      }
      // 右侧
      .s1-con {
        width: 32%;
        height: 100%;
        // background-color: burlywood;
        // margin-left: 20px;
        box-sizing: border-box;
        // 循环项
        .s1-item {
          width: 180px;
          height: 180px;
          background-color: pink;
          float: left;
          margin: 14px 5px;
          position: relative;
          &:hover {
            p {
              opacity: 1;
            }
          }
          //  图片
          img {
            width: 100%;
            height: 180px;
            // background-color: #fff;
            position: relative;
          }
          // 标题
          p {
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 40px;
            opacity: 0;
            background-color: rgba(0, 0, 0, 0.5);
            transition: all 0.3s;
            text-align: center;
            line-height: 40px;
            color: #fff;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }

    // 资讯区块2
    .s2 {
      width: 100%;
      height: 400px;
      // background-color: pink;
      margin: 50px auto;
      // padding-top: 30px;
      box-sizing: border-box;
      // 标题
      .s2-title {
        width: 100%;
        height: 60px;
        line-height: 60px;
        font-size: 16px;
        font-weight: bold;
        // padding-left: 30px;
        border-left: 6px solid #1a82cc;
        box-sizing: border-box;
        background-color: #fff;
        margin-bottom: 15px;
        span {
          margin-left: 30px;
        }
      }
      // 下层
      .s2-con {
        width: 100%;
        height: 340px;
        // background-color: red;
        display: flex;
        justify-content: space-between;
        // 左侧
        .s2-1 {
          width: 70%;
          height: 100%;
          // background-color: sandybrown;
          // 循环项
          .s2-item {
            width: 409px;
            height: 150px;
            background-color: #fff;
            float: left;
            margin: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
            box-sizing: border-box;
            // 左侧图片
            img {
              width: 100px;
              height: 100px;
              // background-color: pink;
            }
            // 右侧信息
            .s2-info {
              width: 250px;
              height: 100px;
              // background-color: red;
              .s2-t {
                font-size: 14px;
                font-weight: bold;
              }
              .s2-brief {
                font-size: 12px;
                color: #8a8686;
                margin: 20px auto;
                white-space: nowrap; //不换行
                overflow: hidden; //超出隐藏
                text-overflow: ellipsis; //变成...
              }
            }
          }
        }
        // 右侧
        .s2-2 {
          width: 30%;
          height: 100%;
          background-color: #fff;
          text-align: center;
          // 图片
          img {
            width: 250px;
            height: 250px;
            // background-color: #1a82cc;
            margin: 20px auto;
            display: block;
          }
          span {
            text-align: center;
            font-size: 16px;
            font-weight: bold;
          }
        }
      }
    }

    // 资讯区块3
    .s3 {
      width: 100%;
      // height: 2000px;
      margin: 80px auto;
      // background-color: pink;
      display: flex;
      justify-content: space-between;
      // 左侧
      .s3-1 {
        width: 70%;
        height: 100%;
        // background-color: #1a82cc;
        margin-right: 40px;
        // 标题
        .s3-title {
          width: 100%;
          height: 60px;
          background-color: #fff;
          font-size: 18px;
          font-weight: bold;
          color: #121212;
          line-height: 60px;
          padding-left: 20px;
          box-sizing: border-box;
          border-left: 6px solid #1a82cc;
        }

        // 下层容器
        .s3-con {
          width: 100%;
          margin: 10px auto;
          ul {
            width: 100%;
            // 循环项
            .s3-item {
              width: 100%;
              height: 200px;
              background-color: #eee;
              margin: 15px auto;
              @include flex();
              // 左侧
              .s3-img {
                width: 200px;
                height: 200px;
                background-color: red;
                img {
                  width: 100%;
                  height: 100%;
                }
              }
              // 右侧
              .s3-info {
                width: 650px;
                height: 100%;
                // background-color: #c60023;
                padding: 20px;
                box-sizing: border-box;
                // 标题
                .s3-t {
                  font-size: 16px;
                  font-weight: bold;
                }
                // 时间 / 作者层
                .s3-user {
                  margin: 25px auto;
                  span {
                    font-size: 14px;
                    color: #121212;
                    margin: 0 15px;
                  }
                }
                // 简介
                .s3-brier {
                  font-size: 13px;
                  margin-top: 20px;
                  width: 500px;
                  // background-color: #fff;

                  p {
                    white-space: nowrap; //不换行
                    overflow: hidden; //超出隐藏
                    text-overflow: ellipsis; //变成...
                  }
                }
              }
            }
          }
        }
      }
      // 右侧
      .s3-2 {
        width: 30%;
        height: 100%;
        // background-color: aqua;
        box-sizing: border-box;
        // 资讯区块1
        .ss3-1 {
          // background-color: pink;
          width: 100%;
          height: 400px;
          margin-bottom: 40px;
          box-sizing: border-box;
          // 标题
          .ss3-title {
            width: 100%;
            height: 50px;
            line-height: 50px;
            font-size: 16px;
            font-weight: bold;
            border-left: 6px solid #1a82cc;
            padding-left: 20px;
            box-sizing: border-box;
            background-color: #fff;
            // border-bottom: 2px solid #1a82cc;
            margin-bottom: 30px;
          }

          // 主体内容
          .ss3-con {
            width: 100%;
            // 循环项
            .ss3-item {
              width: 100%;
              height: 100px;
              background-color: #fff;
              display: flex;
              align-items: center;
              margin: 5px auto;
              // 左侧id
              .ss3-id {
                width: 100px;
                height: 100px;
                font-size: 18px;
                text-align: center;
                line-height: 100px;
                background-color: #1a82cc;
                color: #fff;
              }
              // 右侧信息
              .ss3-info {
                width: 70%;
                padding: 10px;
                box-sizing: border-box;
                .ss3-t {
                  margin: 10px auto;
                }
                .ss3-brief {
                  width: 200px;
                  white-space: nowrap; //不换行
                  overflow: hidden; //超出隐藏
                  text-overflow: ellipsis; //变成...
                }
              }
            }
          }
        }

        // banner1
        .s3-banner {
          width: 100%;
          height: 300px;
          // background-color: red;
          background: url("/imgs/14.jpg") no-repeat center;
          background-size: cover;
          margin: 40px auto;
        }

        // 资讯区块2
        .ss3-2 {
          width: 100%;
          height: 500px;
          margin: 30px auto;
          // background-color: #fff;
          // 标题
          .ss3-title {
            width: 100%;
            height: 50px;
            padding-left: 20px;
            border-left: 6px solid #1a82cc;
            line-height: 50px;
            font-size: 16px;
            font-weight: bold;
            box-sizing: border-box;
            // background-color:coral;
            border-bottom: 1px sollid #121212;
            margin-bottom: 20px;
            background-color: #fff;
          }
          // 容器
          .ss3-con {
            width: 100%;
            // height: 350px;
            background-color: #fff;
            // 循环项
            .ss3-lsit {
              width: 140px;
              // height: 140px;
              background-color: #fff;
              float: left;
              margin: 14px;
              text-align: center;
              // 图片
              img {
                width: 100%;
                height: 130px;
              }
              span {
                font-size: 14px;
                color: #121212;
              }
            }
          }
        }

        // banner2
        .s3-banenr2 {
          width: 100%;
          height: 300px;
          // background-color: red;
          background: url("/imgs/15.jpg") no-repeat center;
          background-size: cover;
          margin: 40px auto;
        }
      }
    }
  }
}
</style>
