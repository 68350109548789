<template>
  <div class="header">
    <div class="container">
      <!-- logo 文字 -->
      <div class="n-logo">
        <!-- <img src="/imgs/logo.png" alt=""> -->
        <h4>宽腾合一 </h4>
      </div>
      <!-- 导航 -->
      <div class="n-list">
        <a :href="href">首页</a>
        <a href="javascript:;" @click="goAc1()">有偿资讯</a>
        <!-- <a href="javascript:;" @click="goAc2()">热门新闻</a> -->
        <!-- <a href="javascript:;" @click="goAc3()">解决方案</a> -->
        <!-- <a href="javascript:;" @click="goInfo()">关于我们</a> -->
      </div>
      <!-- 登录注册 -->
      <div class="n-login">
        <!-- 登录注册 -->
        <!-- <span class="n-login" @click="goLogin()" v-show="this.LoggedShow">
          <i class="iconfont icon-denglu"></i>
          登录
        </span>

        <span class="n-login" @click="goRegister()" v-show="this.LoggedShow">
          <i class="iconfont icon-zhuce"></i>
          注册
        </span> -->
        <div class="n-login2" @click="goLogin()" v-show="this.LoggedShow">登录/注册</div>

        <!-- 用户名 -->
        <span class="n-info" v-show="!this.LoggedShow" v-text="this.nv_user.Username"
          ><i class="iconfont icon-denglu"></i
        ></span>

        <!-- 退出登录 -->
        <span class="n-info" @click="tolg()" v-show="!this.LoggedShow">
          <i class="iconfont icon-tuichudenglu"></i>
          退出登录
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "nav-header",
  props: {
    href: String,
  },
  data() {
    return {
      // 按钮显示/隐藏
      LoggedShow: true,
      // 获取到 user 用户信息
      nv_user: JSON.parse(window.localStorage.getItem("user")),
      // 存储状态
      state: JSON.parse(window.localStorage.getItem("state")),
    };
  },
  mounted() {
    this.logged();
  },
  methods: {
    // 登录时隐藏逻辑
    logged() {
      if (this.state == "0") {
        this.LoggedShow = false;
      }
    },

    // 登录跳转
    goLogin() {
      this.$router.push("/login");
    },
    // 注册跳转
    goRegister() {
      this.$router.push("/register");
    },
    // 退出登录
    tolg() {
      window.localStorage.setItem("state", "10");
      this.LoggedShow = true;
      this.$router.push("/#/");
      window.location.reload();
      // this.$forceUpdate()
    },

    // 跳转最新文章
    goAc1() {
      this.$router.push("/ac1");
    },
    // 跳转行业资讯
    goAc2() {
      this.$router.push("/ac2");
    },
    // 跳转独加新闻
    goAc3() {
      this.$router.push("/ac3");
    },
    // 跳转关于我们
    goInfo() {
      this.$router.push("/info");
    },
    // 跳转注册协议

    goXy() {
      this.$router.push("/xy");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.header {
  width: 100%;
  background-color: #ffffff;
  height: 80px;
  border-bottom: 2px solid #1a82cc;
  .container {
    @include flex();
    height: 100%;
    // logo 文字
    .n-logo {
      font-size: 21px;
      font-weight: bold;
    }
    // 导航
    .n-list {
      // width: 400px;
      a {
        display: inline-block;
        width: 80px;
        height: 25px;
        // background-color: #ff9900;
        border-radius: 15px;
        text-align: center;
        line-height: 25px;
        font-size: 16px;
        margin: 0 5px;
        box-sizing: border-box;
        color: #121212;
        transition: all 0.3s;
        &:hover {
          color: #1a82cc;
        }
      }
    }

    // 登录 / 注册
    .n-login {
      span {
        height: 38px;
        width: 65px;
        background: #1a82cc;
        float: left;
        color: #fff;
        border: 1px solid #1a82cc;
        font-size: 13px;
        vertical-align: middle;
        margin-right: 10px;
        text-align: center;
        line-height: 38px;
        cursor: pointer;
      }
      .n-login2 {
        background-color: transparent;
        font-size: 11px;
        cursor: pointer;
        background-color: #1a82cc;
        width: 79px;
        height: 35px;
        text-align: center;
        line-height: 35px;
        /* padding: 4px; */
        color: #fff;
      }
    }
  }
}
</style>
